.wrappers{
    background-color: #E7162D;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
 min-height: 100vh;
}
.header{
    text-align: right;
    display: flex;
    width: 100%;
    /* border: 1px solid black; */
    /* padding-top: 1rem; */
    margin-bottom: 1rem;
}

.header img{
    margin-right: 20px;
    margin-top: 13px;
    cursor: pointer;
}
.container{
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-top: -10px;
}

.backIcon{
    width: 6%;
}

.upperSection {
display: flex;
gap: 10px;
justify-content: flex-start;
align-items: center;
margin-bottom: 1rem;
}
.upperSection span{
    font-size: larger;
}
.formWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.formWrapper input{
    margin-bottom: 6px;
    border: none;
    padding:5px 8px;
    /* max-height: 1.2rem; */
}
.footer{
  margin-top: 1.8rem;
cursor: pointer;
   text-align: center;
  margin-bottom: 2rem;

}

.subHead{
    font-size: smaller;
    display: block;
    margin-bottom: 2.5rem;
    margin-top: 1.5rem;
}
@media screen and (max-width:933px) {
    .wrappers{
        height: auto;
    }
  
}
@media screen and (min-width:650px) and (max-width:925px){
    .wrappers{
    height: auto;
    }
}

#emailErrors, #otpError, #firstnameError, #surnameError, #mobError {
    color: white;
    text-shadow: 2px 2px 1px #000000;
}

.backSection{
    display: flex;
    align-items: center;
    /* border: 1px solid; */
    width: 80%;
    padding-left: 4rem;
}
.backSection span{
    margin-top: 12px;
}