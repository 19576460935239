.App{
 
  display:flex;
  
}
.register-section{
  background-color: #E7162D;
  min-height: 100vh;
  width: 30%;
  color: white;
 display: flex;
 justify-content: center;
 padding-top: 25px;
  /* flex-direction: column; */

}
.main-section{
  width: 70%;
  /* padding: 25px; */
      max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover; 
background-image: url('./images/web-bg.png');
}

.logo{
position: absolute;
top: 25px;
left: 25px;
width: 9rem;
cursor: pointer;
height: 4rem;
}
.content-container{
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
    bottom: 6rem;
}
.coin-pic{
  width: 12rem;
}
.content-image{
  width: 60%;
}
.content-container span{
  color: #FFFFFF;
  margin-top: 10px;
  font-size: 18px;
}

.image-section{
  width: 100%;

  display: flex;

  padding-bottom: 0px;
  justify-content: space-between;
  position: absolute;
  width: 70%;
  bottom:10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.direcror-section{
  width: 12rem;
position: relative;
height: 14rem;
right: 3%;
display: flex;
}
.ceo-section{
  display: flex;
  width: 12rem;
  height: 14rem;

}
.preist-pic{
  width: 12rem;
  position: relative;
  left: 12rem;
  top: 19%;
  max-height: 12rem;
}
.priest-section{
  display: flex;

}
.director-pic{
  width: 96%;
  height: 90%;
    position: relative;
    top: 14%;

}
.ceo-pic{
  width: 100%;
  height: 90%;
  position: relative;
    top: 14%;
}
.ceo-label{
  position: relative;
  top: 80%;
  width: 10rem;
  height: 16%;
  right: 11rem;
z-index: 2;
}
.priest-label{
  position: relative;
  top: 78%;
  right: 0.5rem;
  width: 12rem;
  height: 18%;
}
.director-label{
  position: relative;
  top: 80%;
  right: 11rem;
  width: 12rem;
  height: 16%

}
.registeration-form{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.registeration-form label{
  font-size: x-small;
  display: block;
  margin-bottom: -7px;
}
.registeration-form input{
  height: 1.5rem;
  padding: 5px 8px;
  border: none;
  margin-top: 5px;
}

.form-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;
  width: 294px;
}
.senco-hyperlink{
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  font-size: smaller;
}
.header> h3{
  margin-bottom:0px ;
  font-size: x-large;

}
.header span{
  font-size: smaller;
  display: block;
  margin-bottom: 1rem;
}

.register-btn{
  background-color: black;
  border: none;
  color: white;
  width: 100%;
  height: 32px;
  font-size: x-small;
  letter-spacing: 0.56px;
  cursor: pointer;
  background: #000000 0% 0% no-repeat padding-box;
opacity: 1;
margin-top:10px ;
}

.otp-btn{
  border: none;
  color: white;
  width: 65%;
  height: 32px;
  font-size: x-small;
  letter-spacing: 0.56px;
  cursor: pointer;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 10px;
  
}

.left-img-section{
  display: flex;
  width: 67%;
}
@media (max-width: 820px ) {
 .App{
  flex-direction: column;
 }



 .main-section, .register-section{
  width: 100%;
  min-height: 50rem;
 }
.image-section{
  position: relative;
  top:10%

}
}
 @media screen and (max-width: 1030px ){
  .preist-pic{
    position: relative;
    left: 5rem;
  }
  .priest-label{
    position: relative;
    right:7.5rem
  }
 }
.footer{
  position: relative;
text-align: center;
  font-size: x-small;
  cursor: pointer;
}
.form-head{
  display: flex;
  align-items: center;
  gap: 5px;
 
  margin-bottom: -20px;
}
.circle{
  width: 5%;
  height: 5%;
margin-bottom: 10px;
}
.register-section a{
text-decoration: none;
color: white;
}
.subHead{
  font-size: smaller;
  display: block;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.otpBtnWrapper{
      display: flex;
      justify-content: center;
}

    #emailErrors, #otpError, #firstnameError, #surnameError, #mobError, #apiresponseMsg {
            color: white;
            text-shadow: 2px 2px 1px #000000;
  }
    