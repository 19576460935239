*{
    padding:0;
    margin:0;
    box-sizing:border-box;

}
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
body{
    width:100% !important;
    height: 100vh !important;
    overflow:hidden !important; 
    background-image: url('./images/web-bg.png');
    /* background-color: red; */
    background-size: cover;
    background-repeat: no-repeat;
    font-family: 'Lato', sans-serif;
  
}
.full-contianer{
    display: flex;
    flex-direction: column;
}

.navbar-section{
    display: inline-flex;
    justify-content: space-between !important;
    align-content: center;
    align-items:center;
    padding-left: 40px;
    padding-top: 30px;
    padding-right: 20px;
    position: fixed;
    width:100%;
    height:80px;
}

.logo{
    width:160px;
    height:80px;
   
    /* border:1px solid red; */

}
.logoicon{
    width:100%;
    height:100%;
    object-fit:fill;    
}
.navbar-text{
    color:white;
    cursor: pointer;
    font-size: 15px;
    letter-spacing: 0.4px;
   position: absolute;
   right:5px;
}

.body-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
   
   
 
     /* Ensure the section takes the full viewport height */
  }
  
.coin-logo{
    width:200px;
    height:170px;
    /* border: 1px solid yellow; */
}
.lakshmicon{
    width:100%;
    height:100%;
    object-fit: cover;
}

.thank-you-text{
    color:white;
    letter-spacing: 0.48px;
    font-size: 35px;
    font-weight: 900;
}
.succes-text{
    color:white;
    margin-top: 50px;
    letter-spacing: 0.48px;
    font-size: 20px;
    
}
.email-notification-text{
    color:white;
    margin-top: 15px;
    letter-spacing: 0.48px;
    font-size: 20px;
}
.end-diwali-text{
    color:white;
    margin-top: 15px;
    letter-spacing: 0.48px;
    font-size: 20px;
}
.footer-section {
    position: absolute;
    /* border:1px solid blue; */
   bottom: 10px ;
    width: 100%; /* Make sure it spans the full width of the viewport. */
    text-align: center;
    color:white;
    text-align: center;
    justify-content: flex-end;
    align-items: flex-end;
    /* border:1px solid blue; */
  }

  .footer-text{
    font-size: 15px;
    letter-spacing: 0.3px;
    font-weight: 100;
    cursor:pointer;
  }

  .coin-logo{
    display: block ;
   }
   .logoicon{
    display: block ;

}
.visit-text{
    display: block;
    font-weight: 100;
}
.web-text{
    display: none;
}


/* For landscape mode on mobile devices */
@media (max-width: 896px) and (orientation: landscape) {
    body{
        height:auto;
        overflow: scroll !important;
        background-image: url('./images/web-bg.png');
        /* background-color: red; */
        background-size: auto ;
        background-repeat: no-repeat;
    }
 
.footer-section {
        position:relative;
        margin-top:0px  ;
        bottom:20px !important;
        left:0;
        width:100%;
        text-align: center;
        font-size:14px; /* Adjust the font size as needed */
    }
    
    .coin-logo{
        display: none  ;
       }
       .logoicon{
        display: none ;
    
    }
    .visit-text{
        display: none;
       
    }
    .web-text{
        display: block;
    }
    .navbar-text{
        cursor: none;
    }
    .footer-text{
        font-size: 15px;
        letter-spacing: 0.3px;
        font-weight: 100;
        cursor:none;
      }
    /* Add more styles as needed for specific elements */
}

@media screen and (max-width:767px){
   .coin-logo{
    display: none;
   }
   .body-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
   padding-left: 10px;
   padding-right: 10px;
     /* Ensure the section takes the full viewport height */
  }
.logoicon{
    display: none;

}
.visit-text{
    display: none;
   
}
.web-text{
    display: block;
}
.navbar-text{
    cursor: none;
}
.footer-text{
    font-size: 15px;
    letter-spacing: 0.3px;
    font-weight: 100;
    cursor:none;
  }

}
/* 
/* @media (max-width: 1023px) {
    .navbar-text{
        cursor: none;
    }
} */ 