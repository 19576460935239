.wrapper{
    background-image: url('../images/mob-bg@2x.png');
    width: 100%;
    overflow-y: scroll;
    
}
.header{
    text-align: right;
    padding: 10px;
    padding-bottom: 0px;
}
.content{
    width: 75%  ;
    margin-bottom: 1rem;
}
.subText{
    color: #ffffff;
    font-size: 18px;
    padding: 0px 5px;
}
.logo{
    width: 30%;
   cursor: pointer;
}
.coin{
    width: 50%;
    margin: -1rem;
}
.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.applyBtn{
    background-color: black;
    color: white;
    width: 70%;
    height: 3rem;
    margin: 1rem;
    border: none;
    cursor: pointer;
    letter-spacing: 0.56px;
    font-size: 18px;
}
.imgSection{
    display: flex;
    align-items: flex-end;
    margin-top: -35px;
}
.ceoPic{
    width: 54%;
    position: relative;
    right: 13%;
}
.directorPic{
    width: 47%;
    position: relative;
    height: 40%;
    right: 40%; 
}

.priestPic{
    width: 43%;
    position: relative;
    /* height: 11rem; */
    right: 39%;
}
.footer{
    position: relative;
    bottom: -9rem;
    color: white;
    text-shadow: 2px 2px 1px #000000;
    z-index: 2;
    margin-bottom: 5px;
    cursor: pointer;
}
@media screen and (min-width:450px) and (max-width:900px) {

    .footer{
        position: relative;
        bottom: -24rem;
    }
    .imgSection{
        margin-top: 0px;
    }
}